<template>
  <div>
    <div
      class="d-flex flex-wrap justify-content-between flex-md-nowrap align-items-center"
    >
      <h4 class="d-flex align-items-center">
        <feather-icon size="18" icon="FileTextIcon" />
        <span class="ml-1">{{
          newContractData.id ? "修改合約" : "創建合約"
        }}</span>
      </h4>
      <div
        class="d-flex"
        v-if="$router.currentRoute.name == 'contracts-create'"
      >
        <b-button
          variant="primary"
          class="mobile-w100 mr-sm-2 mr-0 mt-2 mt-sm-0"
          @click="setLocalStorage"
        >
          <span class="text-nowrap">暫存數據</span>
        </b-button>
        <b-button
          variant="success"
          class="mobile-w100 mr-sm-2 mr-0 mt-2 mt-sm-0"
          @click="getLocalStorage"
        >
          <span class="text-nowrap">拿取暫存數據</span>
        </b-button>
        <b-button
          variant="danger"
          class="mobile-w100 mt-2 mt-sm-0"
          @click="cleanLocalStorage"
        >
          <span class="text-nowrap">清除暫存數據</span>
        </b-button>
      </div>
    </div>

    <b-overlay
      :show="loading"
      spinner-variant="primary"
      spinner-type="grow"
      spinner-small
      rounded="sm"
    >
      <form-wizard
        color="#22c48b"
        :title="null"
        :subtitle="null"
        shape="square"
        finish-button-text="Submit"
        back-button-text="Previous"
        class="mb-3"
        @on-complete="formSubmitted"
      >
        <tab-content title="合約資料" :before-change="validationForm">
          <validation-observer ref="refFormObserver" tag="form">
            <b-row>
              <!-- 合約編號 -->
              <b-col cols="12" md="6">
                <b-form-group
                  label="合約編號："
                  label-for="contract_number"
                  label-cols-md="4"
                  class="required"
                >
                  <b-form-input
                    id="contract_number"
                    v-model="newContractData.contract_number"
                    placeholder="自動獲取"
                    disabled
                  />
                </b-form-group>
              </b-col>

              <!-- 借貸類型 -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="借貸類型"
                  rules="required"
                >
                  <b-form-group
                    label="借貸類型："
                    label-for="loan_type_id"
                    label-cols-md="4"
                    class="required"
                  >
                    <div class="form-col-select">
                      <v-select
                        v-model="newContractData.loan_type_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="loanTypeOptions"
                        :clearable="false"
                        label="name"
                        :reduce="(option) => option.id"
                        :placeholder="getArrayString(loanTypeOptions)"
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <hr class="w-100 my-2" />
            <b-row>
              <b-col cols="12" md="12">
                <h4>放債人資料</h4>
              </b-col>

              <b-col cols="12" md="12" v-if="!newContractData.id">
                <validation-provider
                  #default="validationContext"
                  name="放債人"
                  rules="required"
                >
                  <b-form-group
                    label="放債人："
                    label-for="supplier_create_id"
                    label-cols-md="2"
                    class="required"
                  >
                    <div class="form-col-select">
                      <v-select
                        v-model="newContractData.supplier_create_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="supplierOptions"
                        :clearable="false"
                        label="company_name_cn"
                        :reduce="(option) => option.id"
                        placeholder="選擇放債人"
                        disabled
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="放債人名稱(中)"
                  rules="required"
                >
                  <b-form-group
                    label="放債人名稱(中)："
                    label-for="supplier_name_cn"
                    label-cols-md="2"
                    class="required"
                  >
                    <b-form-input
                      id="supplier_name_cn"
                      type="text"
                      v-model="newContractData.supplier_name_cn"
                      :state="getValidationState(validationContext)"
                      placeholder="放債人名稱(中)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="放債人名稱(英)"
                  rules="required"
                >
                  <b-form-group
                    label="放債人名稱(英)："
                    label-for="supplier_name_en"
                    label-cols-md="2"
                    class="required"
                  >
                    <b-form-input
                      id="supplier_name_en"
                      type="text"
                      v-model="newContractData.supplier_name_en"
                      :state="getValidationState(validationContext)"
                      placeholder="放債人名稱(英)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="放債人牌照號碼"
                  rules="required"
                >
                  <b-form-group
                    label="放債人牌照號碼："
                    label-for="moneylender_number"
                    label-cols-md="2"
                    class="required"
                  >
                    <b-form-input
                      id="moneylender_number"
                      type="text"
                      v-model="newContractData.moneylender_number"
                      :state="getValidationState(validationContext)"
                      placeholder="放債人牌照號碼"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="註冊地址"
                  rules="required"
                >
                  <b-form-group
                    label="註冊地址："
                    label-for="supplier_address"
                    label-cols-md="2"
                    class="required"
                  >
                    <b-form-input
                      id="supplier_address"
                      type="text"
                      v-model="newContractData.supplier_address"
                      :state="getValidationState(validationContext)"
                      placeholder="註冊地址"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

                <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="代表職員"
                  rules="required"
                >
                  <b-form-group
                    label="代表職員："
                    label-for="contact_name"
                    label-cols-md="2"
                    class="required"
                  >
                    <b-form-input
                      id="contact_name"
                      type="text"
                      v-model="newContractData.contact_name"
                      :state="getValidationState(validationContext)"
                      placeholder="代表職員"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <hr class="w-100 my-2" />
            <b-row>
              <b-col cols="12" md="12">
                <h4>合約詳情</h4>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="借款人姓名(中)"
                  rules="required"
                >
                  <b-form-group
                    label="借款人姓名(中)："
                    label-for="name_cn"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      id="name_cn"
                      type="text"
                      v-model="newContractData.name_cn"
                      :state="getValidationState(validationContext)"
                      placeholder="借款人姓名(中)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="借款人姓名(英)"
                  rules="required"
                >
                  <b-form-group
                    label="借款人姓名(英)："
                    label-for="name_en"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      id="name_en"
                      type="text"
                      v-model="newContractData.name_en"
                      :state="getValidationState(validationContext)"
                      placeholder="借款人姓名(英)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- id_number -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="身份證號碼"
                  rules="required"
                >
                  <b-form-group
                    label="身份證號碼："
                    label-for="id_number"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      id="id_number"
                      v-model="newContractData.id_number"
                      @input="IsHKID(newContractData.id_number)"
                      :state="getValidationState(validationContext)"
                      placeholder="輸入身份證號碼"
                    />
                    <b-form-invalid-feedback :force-show="true">
                      {{ idError ? idError : validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="商業登記號碼："
                  label-for="br_number"
                  label-cols-md="4"
                  class=""
                >
                  <b-form-input
                    id="br_number"
                    type="text"
                    v-model="newContractData.br_number"
                    placeholder="商業登記號碼"
                  />
                </b-form-group>
              </b-col>

              <!-- loan_date -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`loan_date`"
                  name="貸款日期"
                  rules="required"
                >
                  <b-form-group
                    label="貸款日期："
                    :label-for="`loan_date`"
                    label-cols-md="4"
                    class="required"
                  >
                    <flat-pickr
                      v-model="newContractData.loan_date"
                      class="form-control"
                      :disabled="disable_field"
                      :config="config3"
                      @input="calcLoanCloseDate(newContractData)"
                      placeholder="輸入貸款日期"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`loan_amount`"
                  name="貸款本金"
                  rules="required|min_value:0"
                >
                  <b-form-group
                    label="貸款本金"
                    :label-for="`loan_amount`"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      :id="`loan_amount`"
                      :disabled="disable_field"
                      v-model="newContractData.amount"
                      type="number"
                      :state="getValidationState(validationContext)"
                      placeholder="輸入貸款本金"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- 利息計算 -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="利息計算"
                  rules="required"
                >
                  <b-form-group
                    label="利息計算："
                    label-for="interest_type_id"
                    label-cols-md="4"
                    class="required"
                  >
                    <div class="form-col-select">
                      <v-select
                        v-model="newContractData.interest_type_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="interestTypeOptions"
                        :clearable="false"
                        label="name"
                        :reduce="(option) => option.id"
                        :disabled="disable_field"
                        :placeholder="getArrayString(interestTypeOptions)"
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`period`"
                  name="總期數"
                  rules="required|min_value:0"
                >
                  <b-form-group
                    label="總期數"
                    :label-for="`period`"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      :id="`period`"
                      v-model="newContractData.period"
                      type="number"
                      :state="getValidationState(validationContext)"
                      placeholder="輸入總期數"
                      :disabled="disable_field"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- is_monthly -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`v-loan_is_monthly`"
                  name="供款週期"
                  rules="required"
                >
                  <b-form-group
                    label="供款週期："
                    :label-for="`loan_is_monthly`"
                    label-cols-md="4"
                    class="required"
                  >
                    <div class="form-col-select">
                      <v-select
                        v-model="newContractData.is_monthly"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="loan_is_monthly"
                        :clearable="false"
                        label="name"
                        :reduce="(option) => option.id"
                        @input="calcLoanCloseDate(newContractData)"
                        :placeholder="getArrayString(loan_is_monthly)"
                        :disabled="disable_field"
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`annual_interest_rate`"
                  name="年利率"
                  rules="required|min_value:0"
                >
                  <b-form-group
                    label="年利率"
                    :label-for="`annual_interest_rate`"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      :id="`annual_interest_rate`"
                      v-model="newContractData.annual_interest_rate"
                      type="number"
                      :state="getValidationState(validationContext)"
                      @input="calcLoanCloseDate(newContractData)"
                      placeholder="輸入年利率"
                      :disabled="disable_field"
                      v-limit-number:max="48"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- first_pay_date -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`v-loan_first_pay_date`"
                  name="首次供款日期"
                  rules="required"
                >
                  <b-form-group
                    label="首次供款日期："
                    :label-for="`loan_first_pay_date`"
                    label-cols-md="4"
                    class="required"
                   
                  >
                    <!-- class="required" -->
                    <div class="d-flex">
                      <flat-pickr
                        v-model="newContractData.first_pay_date"
                        class="form-control"
                        :config="config2"
                        placeholder="輸入首次供款日期"
                        :disabled="disable_field"
                        @input="calcLoanCloseDate(newContractData)"
                      />
                      <feather-icon v-if="newContractData.id == 0" @click="newContractData.first_pay_date = null" class="resetButton" size="18" icon="RotateCcwIcon" />
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- pay_date -->
              <b-col cols="12" md="6">
                <b-form-group label="供款日：" label-cols-md="4">
                  <b-form-input
                    :id="`pay_date`"
                    v-model="newContractData.pay_date"
                    placeholder="輸入供款日"
                    :disabled="disable_field"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="供款金額(每期)："
                  label-for="average_amount"
                  label-cols-md="4"
                  class="required"
                >
                  <b-form-input
                    id="average_amount"
                    :value="
                      newContractData.average_amount &&
                      formatInput(newContractData.average_amount)
                    "
                    placeholder="自動計算"
                    disabled
                    :key="`average_amount_${trigger ? 1 : 0}`"
                    type="number"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="總供款額："
                  label-for="total_amount"
                  label-cols-md="4"
                  class="required"
                >
                  <b-form-input
                    id="total_amount"
                    :value="
                      newContractData.total_amount &&
                      formatInput(newContractData.total_amount)
                    "
                    placeholder="自動計算"
                    disabled
                    :key="`total_amount_${trigger ? 1 : 0}`"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="總利息："
                  label-for="total_interest"
                  label-cols-md="4"
                  class="required"
                >
                  <b-form-input
                    id="total_interest"
                    :value="
                      newContractData.total_interest &&
                      formatInput(newContractData.total_interest)
                    "
                    placeholder="自動計算"
                    disabled
                    :key="`total_interest_${trigger ? 1 : 0}`"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="支付方式"
                  rules="required"
                >
                  <b-form-group
                    label="支付方式"
                    label-for="payment_method_id"
                    label-cols-md="4"
                    class="required"
                  >
                    <div class="form-col-select">
                      <v-select
                        v-model="newContractData.payment_method_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="paymentMethodOptions"
                        :clearable="false"
                        label="name"
                        :reduce="(option) => option.id"
                        :placeholder="getArrayString(paymentMethodOptions)"
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>

            <hr class="w-100 my-2" />
            <!-- <div
                class="d-flex mt-1 flex-wrap flex-sm-nowrap justify-content-end"
              >
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-sm-2 mobile-w100"
                  type="submit"
                >
                  <feather-icon size="16" class="mr-1" icon="CheckCircleIcon" />
                  提交
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  type="button"
                  variant="outline-secondary"
                  @click="hide"
                  class="mt-1 mt-sm-0 mobile-w100"
                >
                  取消
                </b-button>
              </div> -->
          </validation-observer>
        </tab-content>
        <tab-content
          title="貨款申請書"
          :before-change="validationInformationForm"
        >
          <validation-observer ref="refInformationFormObserver" tag="form">
            <b-row v-if="newContractData.loan_information">
              <b-col cols="12" md="12">
                <h4>貸款申請書</h4>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`loan_amount_1`"
                  name="貸款本金"
                  rules="required|min_value:0"
                >
                  <b-form-group
                    label="貸款額"
                    :label-for="`loan_amount_1`"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      :id="`loan_amount_1`"
                      v-model="newContractData.amount"
                      type="number"
                      :state="getValidationState(validationContext)"
                      placeholder="輸入貸款本金"
                      :disabled="disable_field"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`period_1`"
                  name="供款期"
                  rules="required|min_value:0"
                >
                  <b-form-group
                    label="供款期"
                    :label-for="`period_1`"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      :id="`period_1`"
                      v-model="newContractData.period"
                      type="number"
                      :state="getValidationState(validationContext)"
                      placeholder="輸入供款期"
                      :disabled="disable_field"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`application_date`"
                  name="申請日期"
                  rules="required"
                >
                  <b-form-group
                    label="申請日期："
                    :label-for="`application_date`"
                    label-cols-md="4"
                    class="required"
                  >
                    <flat-pickr
                      v-model="
                        newContractData.loan_information.application_date
                      "
                      class="form-control"
                      :config="config3"
                      placeholder="輸入申請日期"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`loan_purpose`"
                  name="貸款用途"
                  rules="required"
                >
                  <b-form-group
                    label="貸款用途"
                    :label-for="`loan_purpose`"
                    label-cols-md="4"
                    class="required"
                  >
                    <v-select
                      v-model="newContractData.loan_information.loan_purpose"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="loanPurposeOptions"
                      :clearable="false"
                    />

                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`customer_source`"
                  name="客戶來源"
                  rules="required"
                >
                  <b-form-group
                    label="客戶來源"
                    :label-for="`customer_source`"
                    label-cols-md="4"
                    class="required"
                  >
                    <v-select
                      v-model="newContractData.loan_information.customer_source"
                      :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                      :options="customerSourceOptions"
                      :clearable="false"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <hr class="w-100 my-2" />
            <b-row v-if="newContractData.loan_information">
              <b-col cols="12" md="12">
                <h4>個人資料</h4>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="借款人姓名(中)"
                  rules="required"
                >
                  <b-form-group
                    label="借款人姓名(中)："
                    label-for="name_cn_1"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      id="name_cn_1"
                      type="text"
                      v-model="newContractData.name_cn"
                      :state="getValidationState(validationContext)"
                      placeholder="借款人姓名(中)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="借款人姓名(英)"
                  rules="required"
                >
                  <b-form-group
                    label="借款人姓名(英)："
                    label-for="name_en_1"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      id="name_en_1"
                      type="text"
                      v-model="newContractData.name_en"
                      :state="getValidationState(validationContext)"
                      placeholder="借款人姓名(英)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <!-- id_number -->
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="身份證號碼"
                  rules="required"
                >
                  <b-form-group
                    label="身份證號碼："
                    label-for="id_number_1"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      id="id_number_1"
                      v-model="newContractData.id_number"
                      @input="IsHKID(newContractData.id_number)"
                      :state="getValidationState(validationContext)"
                      placeholder="輸入身份證號碼"
                    />
                    <b-form-invalid-feedback :force-show="true">
                      {{ idError ? idError : validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="性別"
                  rules="required"
                >
                  <b-form-group
                    label="性別："
                    label-for="gender_id"
                    label-cols-md="4"
                    class="required"
                  >
                    <div class="form-col-select">
                      <v-select
                        v-model="newContractData.loan_information.gender_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="genderOptions"
                        :clearable="false"
                        label="gender"
                        :reduce="(option) => option.id"
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`date_of_birth`"
                  name="出生日期"
                  rules="required|min:10"
                  :messages="{ min: '請輸入正確出生日期' }"
                >
                  <b-form-group
                    label="出生日期："
                    :label-for="`date_of_birth`"
                    label-cols-md="4"
                    class="required"
                  >
                    <cleave
                      id="date_of_birth"
                      v-model="newContractData.loan_information.date_of_birth"
                      class="form-control"
                      :raw="false"
                      :options="dateOptions"
                      placeholder="YYYY-MM-DD"
                    />
                    <!-- <flat-pickr
                      v-model="newContractData.loan_information.date_of_birth"
                      class="form-control"
                      :config="{
                        allowInput: true,
                        dateFormat: 'Y-m-d',
                        maxDate: $store.state.app.maxDate,
                      }"
                      placeholder="輸入出生日期"
                    /> -->
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="年齡："
                  :label-for="`age`"
                  label-cols-md="4"
                  class="required"
                >
                  <b-form-input
                    id="age_1"
                    :value="
                      getAge(newContractData.loan_information.date_of_birth)
                    "
                    disabled
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="手提電話1："
                  label-for="mobile"
                  label-cols-md="4"
                  class=""
                >
                  <b-form-input
                    id="mobile"
                    type="text"
                    v-model="newContractData.loan_information.mobile"
                    placeholder="手提電話1"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="手提電話2："
                  label-for="tel"
                  label-cols-md="4"
                  class=""
                >
                  <b-form-input
                    id="tel"
                    type="text"
                    v-model="newContractData.loan_information.tel"
                    placeholder="手提電話2"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="12">
                <validation-provider
                  #default="validationContext"
                  name="通訊地址"
                  rules="required"
                >
                  <b-form-group
                    label="通訊地址："
                    label-for="address"
                    label-cols-md="2"
                    class="required"
                  >
                    <b-form-input
                      id="address"
                      type="text"
                      v-model="newContractData.address"
                      :state="getValidationState(validationContext)"
                      placeholder="通訊地址"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="租金/供樓金額"
                  rules="required"
                >
                  <b-form-group
                    label="租金/供樓金額："
                    label-for="rent_or_mortagage"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      id="rent_or_mortagage"
                      type="number"
                      v-model="
                        newContractData.loan_information.rent_or_mortagage
                      "
                      :state="getValidationState(validationContext)"
                      placeholder="租金/供樓金額"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="生活支出"
                  rules="required"
                >
                  <b-form-group
                    label="生活支出："
                    label-for="living_expenses"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      id="living_expenses"
                      type="number"
                      v-model="newContractData.loan_information.living_expenses"
                      :state="getValidationState(validationContext)"
                      placeholder="生活支出"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <hr class="w-100 my-2" />
            <b-row v-if="newContractData.loan_information">
              <b-col cols="12" md="12">
                <h4>工作資料</h4>
              </b-col>
              <b-col cols="12" md="12">
                <b-form-group
                  label="公司名稱："
                  label-for="company_name"
                  label-cols-md="2"
                  class=""
                >
                  <b-form-input
                    id="company_name"
                    type="text"
                    v-model="newContractData.loan_information.company_name"
                    placeholder="公司名稱"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="12">
                <b-form-group
                  label="公司地址："
                  label-for="company_address"
                  label-cols-md="2"
                  class=""
                >
                  <b-form-input
                    id="company_address"
                    type="text"
                    v-model="newContractData.loan_information.company_address"
                    placeholder="公司地址"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="職位："
                  label-for="position"
                  label-cols-md="4"
                  class=""
                >
                  <b-form-input
                    id="position"
                    type="text"
                    v-model="newContractData.loan_information.position"
                    placeholder="職位"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="工齡："
                  label-for="work_age"
                  label-cols-md="4"
                  class=""
                >
                  <b-input-group>
                    <b-form-input
                      id="work_age"
                      type="number"
                      v-model="newContractData.loan_information.work_age_year"
                      placeholder="年"
                    />
                    <b-input-group-append is-text> 年 </b-input-group-append>
                  </b-input-group>
                  <b-input-group>
                    <b-form-input
                      id="work_age"
                      type="number"
                      v-model="newContractData.loan_information.work_age_month"
                      placeholder="月"
                      v-limit-number:max="12"
                    />
                    <b-input-group-append is-text> 月 </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="支薪方式："
                  label-for="payment_method"
                  label-cols-md="4"
                  class=""
                >
                  <v-select
                    v-model="newContractData.loan_information.payment_method"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="salaryPaymentOptions"
                    :clearable="true"
                    placeholder="支薪方式"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="每月收入"
                  rules="required"
                >
                  <b-form-group
                    label="每月收入："
                    label-for="monthly_salary"
                    label-cols-md="4"
                    class="required|min_value:0"
                  >
                    <b-form-input
                      id="monthly_salary"
                      type="number"
                      v-model="newContractData.loan_information.monthly_salary"
                      placeholder="每月收入"
                      :state="getValidationState(validationContext)"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
            <hr class="w-100 my-2" />
            <b-row v-if="newContractData.loan_information">
              <b-col cols="12" md="12">
                <h4>兼職資料</h4>
              </b-col>
              <b-col cols="12" md="12">
                <b-form-group
                  label="公司名稱："
                  label-for="pt_company_name"
                  label-cols-md="2"
                  class=""
                >
                  <b-form-input
                    id="pt_company_name"
                    type="text"
                    v-model="newContractData.loan_information.pt_company_name"
                    placeholder="公司名稱"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="12">
                <b-form-group
                  label="公司地址："
                  label-for="pt_company_address"
                  label-cols-md="2"
                  class=""
                >
                  <b-form-input
                    id="pt_company_address"
                    type="text"
                    v-model="
                      newContractData.loan_information.pt_company_address
                    "
                    placeholder="公司地址"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="職位："
                  label-for="pt_position"
                  label-cols-md="4"
                  class=""
                >
                  <b-form-input
                    id="pt_position"
                    type="text"
                    v-model="newContractData.loan_information.pt_position"
                    placeholder="職位"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="工齡："
                  label-for="pt_work_age"
                  label-cols-md="4"
                  class=""
                >
                  <b-input-group>
                    <b-form-input
                      id="pt_work_age"
                      type="number"
                      v-model="
                        newContractData.loan_information.pt_work_age_year
                      "
                      placeholder="年"
                    />
                    <b-input-group-append is-text> 年 </b-input-group-append>
                  </b-input-group>
                  <b-input-group>
                    <b-form-input
                      id="pt_work_age"
                      type="number"
                      v-model="
                        newContractData.loan_information.pt_work_age_month
                      "
                      placeholder="月"
                      v-limit-number:max="12"
                    />
                    <b-input-group-append is-text> 月 </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="支薪方式："
                  label-for="pt_payment_method"
                  label-cols-md="4"
                  class=""
                >
                  <v-select
                    v-model="newContractData.loan_information.pt_payment_method"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="salaryPaymentOptions"
                    :clearable="true"
                    placeholder="支薪方式"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="每月收入："
                  label-for="pt_monthly_salary"
                  label-cols-md="4"
                  class=""
                >
                  <b-form-input
                    id="pt_monthly_salary"
                    type="number"
                    v-model="newContractData.loan_information.pt_monthly_salary"
                    placeholder="每月收入"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <hr class="w-100 my-2" />
            <b-row v-if="newContractData.loan_information">
              <b-col cols="12" md="12">
                <h4>信貸狀況</h4>
              </b-col>

              <b-col cols="12" md="12" class="mb-2">
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  variant="primary"
                  class="mr-sm-2 mobile-w100"
                  style="border-radius: 3px"
                  @click="addItem"
                >
                  <feather-icon size="16" class="mr-1" icon="PlusCircleIcon" />
                  新增信貸狀況
                </b-button>
              </b-col>
            </b-row>
            <div
              v-if="
                newContractData.loan_information &&
                newContractData.loan_information.histories
              "
            >
              <b-row
                v-for="(history, index) in newContractData.loan_information
                  .histories"
                :key="`history_${index}`"
                class="pb-2 mb-3"
              >
                <b-col cols="12">
                  <div class="d-flex border rounded">
                    <b-row class="flex-grow-1 p-2">
                      <!-- name -->
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="validationContext"
                          :vid="`v-supplier_name${index}`"
                          name="信貸機構"
                          rules="required"
                        >
                          <b-form-group
                            label="信貸機構："
                            :label-for="`supplier_name${index}`"
                            label-cols-md="3"
                            class="required"
                          >
                            <b-form-input
                              :id="`supplier_name${index}`"
                              v-model="history.supplier_name"
                              :state="getValidationState(validationContext)"
                              placeholder="輸入信貸機構"
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="validationContext"
                          :vid="`loan_type${index}`"
                          name="分期/循環"
                          rules="required"
                        >
                          <b-form-group
                            label="分期/循環"
                            :label-for="`loan_type${index}`"
                            label-cols-md="3"
                            class="required"
                          >
                            <v-select
                              v-model="history.loan_type"
                              :dir="
                                $store.state.appConfig.isRTL ? 'rtl' : 'ltr'
                              "
                              :options="paymentTypeOptions"
                              :clearable="false"
                            />
                            <b-form-invalid-feedback
                              :state="getValidationState(validationContext)"
                            >
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- amount -->
                      <b-col cols="12" md="6">
                        <b-form-group
                          label="貸款金額:"
                          :label-for="`amount_${index}`"
                          label-cols-md="3"
                        >
                          <b-form-input
                            :id="`amount_${index}`"
                            v-model="history.amount"
                            placeholder="輸入貸款金額"
                            type="number"
                            v-integer
                          />
                        </b-form-group>
                      </b-col>

                      <!-- payment_amount -->
                      <b-col cols="12" md="6">
                        <validation-provider
                          #default="validationContext"
                          :vid="`v-supplier_name${index}`"
                          name="信貸機構"
                          rules="required"
                        >
                          <b-form-group
                            label="供款金額："
                            :label-for="`payment_amount_${index}`"
                            class="required"
                            label-cols-md="3"
                          >
                            <b-form-input
                              :id="`payment_amount_${index}`"
                              v-model="history.payment_amount"
                              :state="getValidationState(validationContext)"
                              placeholder="輸入供款金額"
                              type="number"
                              inputmode="numeric"
                              pattern="[0-9]*"
                              min="0"
                              v-integer
                            />
                            <b-form-invalid-feedback>
                              {{ validationContext.errors[0] }}
                            </b-form-invalid-feedback>
                          </b-form-group>
                        </validation-provider>
                      </b-col>

                      <!-- remain_period -->
                      <b-col cols="12" md="6">
                        <b-form-group
                          label="尚欠供款期："
                          :label-for="`remain_period_(${index})`"
                          label-cols-md="3"
                        >
                          <b-form-input
                            :id="`remain_period_(${index})`"
                            v-model="history.remain_period"
                            placeholder="輸入尚欠供款期"
                            v-if="history.loan_type != '循環'"
                            type="number"
                            v-integer
                          />

                          <b-form-input
                            :id="`remain_period_1_(${index})`"
                            disabled
                            placeholder="------"
                            v-if="history.loan_type == '循環'"
                          />
                        </b-form-group>
                      </b-col>

                      <b-col cols="12" md="6">
                        <b-form-group
                          label="總期數："
                          :label-for="`total_period_(${index})`"
                          label-cols-md="3"
                        >
                          <b-form-input
                            :id="`total_period_(${index})`"
                            v-model="history.total_period"
                            placeholder="輸入總期數"
                            type="number"
                            v-integer
                             v-if="history.loan_type != '循環'"
                          />
                           <b-form-input
                            :id="`total_period_(${index})`"
                            disabled
                            placeholder="------"
                            v-if="history.loan_type == '循環'"
                          />
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <div
                      class="d-flex flex-column justify-content-between border-left py-50 px-25"
                    >
                      <feather-icon
                        size="16"
                        icon="PlusIcon"
                        class="cursor-pointer text-success"
                        @click="addItem"
                      />
                      <feather-icon
                        size="16"
                        icon="XIcon"
                        class="cursor-pointer text-danger"
                        @click="removeItem(index, history)"
                      />
                    </div>
                  </div>
                </b-col>
              </b-row>
            </div>
            <hr class="w-100 my-2" />
            <b-row>
              <b-col cols="12" md="2" sm="6">
                <h5>收入</h5>
              </b-col>
              <b-col cols="12" md="2" sm="6">
                {{
                  (newContractData.loan_information &&
                    newContractData.loan_information.monthly_salary) ||
                  0
                }}
              </b-col>
              <b-col cols="12" md="2" sm="6">
                <h5>兼職</h5>
              </b-col>
              <b-col cols="12" md="2" sm="6">
                {{
                  (newContractData.loan_information &&
                    newContractData.loan_information.pt_monthly_salary) ||
                  0
                }}
              </b-col>
              <b-col cols="12" md="2" sm="6">
                <h5>收入總和</h5>
              </b-col>
              <b-col cols="12" md="2" sm="6">
                {{ total_salary }}
              </b-col>
              <b-col cols="12" md="2" sm="6">
                <h5>租金/供樓</h5>
              </b-col>
              <b-col cols="12" md="2" sm="6">
                {{
                  (newContractData.loan_information &&
                    newContractData.loan_information.rent_or_mortagage) ||
                  0
                }}
              </b-col>
              <b-col cols="12" md="2" sm="6">
                <h5>生活支出</h5>
              </b-col>
              <b-col cols="12" md="2" sm="6">
                {{
                  (newContractData.loan_information &&
                    newContractData.loan_information.living_expenses) ||
                  0
                }}
              </b-col>
              <b-col cols="12" md="2" sm="6">
                <h5>供款金額總和</h5>
              </b-col>
              <b-col cols="12" md="2" sm="6">
                {{ total_loan_amount }}
              </b-col>
              <b-col cols="12" md="2" sm="6">
                <h5>可用金額</h5>
              </b-col>
              <b-col cols="12" md="2" sm="6">
                {{ available_amount }}
              </b-col>
              <b-col cols="12" md="2" sm="6">
                <h5>支出總和</h5>
              </b-col>
              <b-col cols="12" md="2" sm="6">
                {{ total_expense }}
              </b-col>
              <b-col cols="12" md="12" sm="12">
              </b-col>
              <b-col cols="12" md="2" sm="6">
                <h5>DSR%</h5>
              </b-col>
              <b-col cols="12" md="10" sm="6">
                <h5>{{ dsr }}</h5>
              </b-col>
              <hr class="w-100 my-2" />
            </b-row>
          </validation-observer>
        </tab-content>

        <tab-content title="供款表" :before-change="validationScheduleForm">
          <validation-observer ref="refScheduleObserver" tag="form">
            <b-row>
              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`loan_date_2`"
                  name="貸款日期"
                  rules="required"
                >
                  <b-form-group
                    label="貸款日期："
                    :label-for="`loan_date_2`"
                    label-cols-md="4"
                    class="required"
                  >
                    <flat-pickr
                      v-model="newContractData.loan_date"
                      class="form-control"
                      :config="config3"
                      @input="calcLoanCloseDate(newContractData)"
                      placeholder="輸入貸款日期"
                      :disabled="disable_field"
                    />
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

                <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`v-loan_first_pay_date_2`"
                  name="首次供款日期"
                  rules="required"
                >
                  <b-form-group
                    label="首次供款日期："
                    :label-for="`loan_first_pay_date_2`"
                    label-cols-md="4"
                    class="required"
                   
                  >
                    <!-- class="required" -->
                    <div class="d-flex">
                      <flat-pickr
                        v-model="newContractData.first_pay_date"
                        class="form-control"
                        :config="config2"
                        placeholder="輸入首次供款日期"
                        :disabled="disable_field"
                        @input="calcLoanCloseDate(newContractData)"
                      />
                      <feather-icon v-if="newContractData.id == 0" @click="newContractData.first_pay_date = null" class="resetButton" size="18" icon="RotateCcwIcon" />
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`loan_amount_2`"
                  name="貸款本金"
                  rules="required|min_value:0"
                >
                  <b-form-group
                    label="貸款本金"
                    :label-for="`loan_amount_2`"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      :id="`loan_amount_2`"
                      v-model="newContractData.amount"
                      type="number"
                      :state="getValidationState(validationContext)"
                      placeholder="輸入貸款本金"
                      :disabled="disable_field"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

            

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`period_2`"
                  name="總期數"
                  rules="required|min_value:0"
                >
                  <b-form-group
                    label="總期數"
                    :label-for="`period_2`"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      :id="`period_2`"
                      v-model="newContractData.period"
                      type="number"
                      :state="getValidationState(validationContext)"
                      placeholder="輸入總期數"
                      :disabled="disable_field"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`annual_interest_rate_2`"
                  name="年利率"
                  rules="required|min_value:0"
                >
                  <b-form-group
                    label="年利率"
                    :label-for="`annual_interest_rate_2`"
                    label-cols-md="4"
                    class="required"
                  >
                    <b-form-input
                      :id="`annual_interest_rate_2`"
                      v-model="newContractData.annual_interest_rate"
                      type="number"
                      :state="getValidationState(validationContext)"
                      placeholder="輸入年利率"
                      :disabled="disable_field"
                      v-limit-number:max="48"
                    />
                    <b-form-invalid-feedback>
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  :vid="`v-loan_is_monthly_2`"
                  name="供款週期"
                  rules="required"
                >
                  <b-form-group
                    label="供款週期："
                    :label-for="`loan_is_monthly_2`"
                    label-cols-md="4"
                    class="required"
                  >
                    <div class="form-col-select">
                      <v-select
                        v-model="newContractData.is_monthly"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="loan_is_monthly"
                        :clearable="false"
                        label="name"
                        :reduce="(option) => option.id"
                        @input="calcLoanCloseDate(newContractData)"
                        :placeholder="getArrayString(loan_is_monthly)"
                        :disabled="disable_field"
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <validation-provider
                  #default="validationContext"
                  name="利息計算"
                  rules="required"
                >
                  <b-form-group
                    label="利息計算："
                    label-for="interest_type_id_2"
                    label-cols-md="4"
                    class="required"
                  >
                    <div class="form-col-select">
                      <v-select
                        v-model="newContractData.interest_type_id"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        :options="interestTypeOptions"
                        :clearable="false"
                        label="name"
                        :reduce="(option) => option.id"
                        :placeholder="getArrayString(interestTypeOptions)"
                        :disabled="disable_field"
                      />
                      <feather-icon size="18" icon="ChevronDownIcon" />
                    </div>
                    <b-form-invalid-feedback
                      :state="getValidationState(validationContext)"
                    >
                      {{ validationContext.errors[0] }}
                    </b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="供款金額(每期)："
                  label-for="average_amount_2"
                  label-cols-md="4"
                  class="required"
                >
                  <b-form-input
                    id="average_amount_2"
                    :value="
                      newContractData.average_amount &&
                      formatInput(newContractData.average_amount)
                    "
                    placeholder="自動計算"
                    disabled
                    :key="`average_amount_2_${trigger ? 1 : 0}`"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="總供款額："
                  label-for="total_amount_2"
                  label-cols-md="4"
                  class="required"
                  :key="`total_amount_2_${trigger ? 1 : 0}`"
                >
                  <b-form-input
                    id="total_amount_2"
                    :value="
                      newContractData.total_amount &&
                      formatInput(newContractData.total_amount)
                    "
                    placeholder="自動計算"
                    disabled
                    :key="`total_interest_2_${trigger ? 1 : 0}`"
                  />
                </b-form-group>
              </b-col>

              <b-col cols="12" md="6">
                <b-form-group
                  label="總利息："
                  label-for="total_interest_2"
                  label-cols-md="4"
                  class="required"
                >
                  <b-form-input
                    id="total_interest_2"
                    :value="
                      newContractData.total_interest &&
                      formatInput(newContractData.total_interest)
                    "
                    placeholder="自動計算"
                    disabled
                    :key="`total_interest_2_${trigger ? 1 : 0}`"
                  />
                </b-form-group>
              </b-col>
            </b-row>
            <hr class="w-100 my-2" />
          </validation-observer>
          <b-table
            ref="refScheduleTable"
            class="position-relative"
            :items="newContractData.schedules"
            responsive
            :fields="tableColumns"
            primary-key="period"
            show-empty
            empty-text="未找到匹配的記錄"
            :key="`schedule_table_${trigger ? 1 : 0}`"
          >
            <template #cell(principal)="data">
              {{ formatInput(data.item.principal) }}
            </template>

            <template #cell(interest)="data">
              {{ formatInput(data.item.interest) }}
            </template>

            <template #cell(payment_amount)="data">
              {{ formatInput(data.item.payment_amount) }}
            </template>

            <template #cell(remaining_principal)="data">
              {{ formatInput(data.item.remaining_principal) }}
            </template>
          </b-table>
          <hr class="w-100 my-2" />
        </tab-content>
      </form-wizard>
    </b-overlay>
  </div>
</template>

<script>
import { FormWizard, TabContent } from "vue-form-wizard";
import {
  BRow,
  BCol,
  BForm,
  BFormFile,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BFormCheckbox,
  BButton,
  BOverlay,
  BTable,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import flatPickr from "vue-flatpickr-component";
import { ref, watch, computed} from "@vue/composition-api";
import vSelect from "vue-select";
import router from "@/router";
import store from "@/store";
import Ripple from "vue-ripple-directive";
import "animate.css";
import {
  ValidationProvider,
  ValidationObserver,
  localize,
  extend,
} from "vee-validate";
import { min_value } from "vee-validate/dist/rules";
import zh_CN from "vee-validate/dist/locale/zh_CN.json";
localize("zh_cn", zh_CN);
import formValidation from "@core/comp-functions/forms/form-validation";
import { required, email } from "@validations";
import "vue-form-wizard/dist/vue-form-wizard.min.css";
import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.us";
import {maxDate} from '@/libs/helper'

extend("min_value", min_value);

export default {
  data() {
    return {
      tem_data:{
          loan_date: null,
          period: null,
          is_monthly: null,
          first_pay_date: null
        },
      dateOptions: {
        date: true,
        delimiter: "-",
        datePattern: ["Y", "m", "d"],
      },
      idOptions: {
        blocks: [1, 1, 1, 1, 1, 1, 1, 2],
        delimiter: " ",
        delimiters: [" ", "", "", "", "", "", "", ")"],
        numericOnly: true,
        uppercase: true,
      },
      idError: null,
      loan_is_monthly: [
        { id: 1, name: "月供" },
        { id: 0, name: "半月供" },
      ],
      tableColumns: [
        { key: "period", label: "期數" },
        { key: "pay_date", label: "供款日" },
        { key: "initial_principal", label: "期初本金" },
        { key: "principal", label: "每期本金" },
        { key: "interest", label: "利息" },
        { key: "payment_amount", label: "供款金額" },
        { key: "remaining_principal", label: "尚欠本金" },
      ],
      customerSourceOptions: [
        "Google ",
        "Yahoo",
        "Facebook",
        "Instagram",
        "報章",
        "朋友介紹",
        "短訊",
        "現有客戶",
        "其他",
      ],
      paymentTypeOptions: ["分期", "循環"],
      loanPurposeOptions: [
        "進修",
        "婚禮",
        "投資",
        "旅遊",
        "醫療",
        "家居維修",
        "創業",
        "購物",
        "公司營運",
        "日常開支",
        "清還債務",
        "其他",
      ],
      salaryPaymentOptions: ["自動轉帳", "現金", "支票"],
      trigger: true,
      config:{
        allowInput: true,
        dateFormat: 'Y-m-d',
        maxDate: maxDate,
      },
      config2:{
        altInput: true,
        altFormat: 'Y-m-d',
        allowInput: true,
        dateFormat: 'Y-m-d',
      },
      config3:{
        allowInput: true,
        dateFormat: 'Y-m-d',
      },
    };
  },
  directives: {
    Ripple,
    integer: {
      bind: function (el, binding) {
        el.addEventListener("input", function (event) {
          const input = event.target;
          const value = Math.round(Number(input.value));
          if (value !== Number(input.value)) {
            input.value = value.toString();
            input.dispatchEvent(new Event("input"));
          }
        });
      },
    },
    "limit-number": {
      bind: function (el, binding) {
        const max = Number(binding.value) || Infinity;
        el.addEventListener("input", function (event) {
          const input = event.target;
          let value = Number(input.value);
          if (isNaN(value) || value < 0) {
            value = 0;
          } else if (value > max) {
            value = max;
          }
          if (input.value !== value.toString()) {
            input.value = value.toString();
            // Dispatch a change event instead of input
            input.dispatchEvent(new Event("change"));
          }
        });
      },
    },
  },
  components: {
    Cleave,
    FormWizard,
    TabContent,
    BForm,
    BFormGroup,
    BFormInput,
    BFormFile,
    BFormInvalidFeedback,
    BFormCheckbox,
    BRow,
    BCol,
    BButton,
    BOverlay,
    flatPickr,
    vSelect,
    BTable,
    BInputGroup,
    BInputGroupAppend,
    ValidationProvider,
    ValidationObserver,
  },
  computed: {
    total_loan_amount() {
      //sum of this.newContractData.loan_information.histories amount field
      let total = 0;
      if (
        this.newContractData.loan_information &&
        this.newContractData.loan_information.histories
      ) {
        this.newContractData.loan_information.histories.forEach((item) => {
          total += Number(item.payment_amount);
        });
      }

      // don't delete below code, it's necessary (this force update the total_loan_amount)
      if(this.trigger || !this.trigger){}

      total +=
          (!this.newContractData.is_monthly ? 2 : 1) *
          this.formatInput(this.newContractData.average_amount);
        return this.formatInput(total);
    },
    total_salary() {
      let monthly_salary =
        (this.newContractData.loan_information &&
          this.newContractData.loan_information.monthly_salary) ||
        0;
      let pt_monthly_salary =
        (this.newContractData.loan_information &&
          this.newContractData.loan_information.pt_monthly_salary) ||
        0;
      return this.formatInput(
        Number(monthly_salary) + Number(pt_monthly_salary)
      );
    },
    total_expense() {
      let living_expenses =
        (this.newContractData.loan_information &&
          this.newContractData.loan_information.living_expenses) ||
        0;
      let rent_or_mortagage =
        (this.newContractData.loan_information &&
          this.newContractData.loan_information.rent_or_mortagage) ||
        0;
      return this.formatInput(
        Number(living_expenses) + Number(rent_or_mortagage) + Number(this.total_loan_amount)
      );
    },
    available_amount() {
      return this.formatInput(this.total_salary - this.total_expense);
    },
    dsr() {
      //'dsr' = intval(($updateData['total_expense']) * 100 / intval($updateData['total_salary'])) . '%';
      return Math.round((this.total_expense * 100) / this.total_salary) + "%";
    },
    disable_field() {
      if (this.newContractData.id > 0) {
        return true;
      } else {
        return false;
      }
    },
    scheduleData() {
      //check if any of the fields are null
      if (
        !this.newContractData ||
        this.newContractData.loan_date == null ||
        this.newContractData.amount == null ||
        this.newContractData.annual_interest_rate == null ||
        this.newContractData.period == null ||
        this.newContractData.interest_type_id == null
      ) {
        return null;
      }

      return {
        loan_date: this.newContractData.loan_date,
        amount: this.newContractData.amount,
        is_monthly: this.newContractData.is_monthly,
        annual_interest_rate: this.newContractData.annual_interest_rate,
        period: this.newContractData.period,
        interest_type_id: this.newContractData.interest_type_id,
        first_pay_date: this.newContractData.first_pay_date,
      };
    },
  },
  watch: {
    newContractData: {
      handler() {
        // This function will run whenever newContractData or any of its nested properties change.
        // You can perform any necessary operations here.
      },
      deep: true,
    },
    scheduleData: {
      handler(newVal, oldVal) {
        // Update scheduleData whenever newContractData changes
        if (newVal != null) {
          this.$store
            .dispatch("app-contract/calculatePaymentSchedule", newVal)
            .then((response) => {
              //console.log(response.data);
              if (this.newContractData) {
                this.newContractData.schedules = response.data.schedules;
                this.newContractData.total_amount = Number(
                  response.data.total_amount
                );
                this.newContractData.average_amount = Number(
                  response.data.average_amount
                );
                this.newContractData.total_interest = Number(
                  response.data.total_amount - this.newContractData.amount
                );
              }
              this.trigger = !this.trigger;
            });
        }
      },
      deep: true,
    },
    "newContractData.name_en": {
      deep: true,
      handler(newVal, oldVal) {
        if (newVal) {
          this.newContractData.name_en = newVal.toUpperCase();
        }
      },
    },
    "newContractData.supplier_create_id": {
      deep: true,
      handler(newVal, oldVal) {
        if (this.newContractData.tem) {
          this.newContractData.tem = false;
          return;
        }

        if (newVal === oldVal) 
          return;

        if(this.newContractData.supplier_id && !this.newContractData.supplier_create_id && this.$route.fullPath.indexOf('copy') > -1)
          this.newContractData.supplier_create_id = this.newContractData.supplier_id
        
        if(!this.supplierOptions || this.supplierOptions.length == 0)
          return;


        if (this.supplierOptions && this.supplierOptions.length > 0 && this.newContractData.id == 0) {
          let supplier = this.supplierOptions.find((x) => x.id == newVal);
       
          if (supplier) {
            this.newContractData.supplier_name_en = supplier.company_name_en;
            this.newContractData.supplier_name_cn = supplier.company_name_cn;
            this.newContractData.moneylender_number = supplier.moneylender_number;
            this.newContractData.supplier_address = supplier.address;
            this.newContractData.contact_name = supplier.contact_name;
          } else {
            this.newContractData.supplier_name_en = "";
            this.newContractData.supplier_name_cn = "";
            this.newContractData.moneylender_number = "";
            this.newContractData.supplier_address = "";
            this.newContractData.contact_name = "";
          }
        }
      },
    },
  },
  methods: {
    setLocalStorage() {
      this.newContractData.tem = true;
      localStorage.setItem("contract", JSON.stringify(this.newContractData));
      this.makeToast("success", "Success", "暫存成功");
    },
    getLocalStorage() {
      if ("contract" in localStorage) {
        this.newContractData = JSON.parse(localStorage.getItem("contract"));
        this.makeToast("success", "Success", "拿取成功");
      } else {
        this.makeToast("danger", "Warning", "沒有暫存");
      }
    },
    cleanLocalStorage() {
      localStorage.removeItem("contract");
      this.makeToast("danger", "Info", "暫存清除成功");
    },
    formatInput(myNumber) {
      try {
        if (myNumber != null) {
          myNumber = Number(Math.round(myNumber));
        }
        return myNumber;
      } catch (error) {
        return null;
      }
    },
    removeItem(index, item) {
      if (item.id > 0) {
        this.$swal({
          title: "你確定嗎?",
          text: "您的行動是最終的，您將無法檢索這記錄。",
          showCancelButton: true,
          confirmButtonText: "刪除",
          cancelButtonText: "取消",
          customClass: {
            confirmButton: "btn btn-primary",
            cancelButton: "btn btn-outline-danger ml-1",
          },
          buttonsStyling: false,
        }).then((result) => {
          if (result.value) {
            this.$store
              .dispatch("app-contract/deleteHistory", { id: item.id })
              .then((response) => {
                this.$swal({
                  text: response.data.message,
                  icon: "success",
                  confirmButtonText: "確定",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
                this.$emit("refetch-data");
              })
              .catch((error) => {
                this.$swal({
                  text: JSON.stringify(error.response.data.message),
                  icon: "error",
                  confirmButtonText: "確定",
                  customClass: {
                    confirmButton: "btn btn-primary",
                  },
                  showClass: {
                    popup: "animate__animated animate__bounceIn",
                  },
                  buttonsStyling: false,
                });
              });
          }
        });
      } else {
        this.newContractData.loan_information.histories.splice(index, 1);
      }
    },
    addItem() {
      this.newContractData.loan_information.histories.push({
        id: 0,
        supplier_name: null,
        amount: null,
        payment_amount: null,
        remain_period: null,
        total_period: null,
      });
    },
    getAge(date_of_birth) {
      if (date_of_birth) {
        let today = new Date();
        let birthDate = new Date(date_of_birth);
        let age = today.getFullYear() - birthDate.getFullYear();
        let m = today.getMonth() - birthDate.getMonth();
        if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
          age--;
        }
        if (age < 18) {
          age = "不得借貸";
        }
        return age;
      }
    },
    validationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.refFormObserver.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationInformationForm() {
      return new Promise((resolve, reject) => {
        this.$refs.refInformationFormObserver.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    validationScheduleForm() {
      return new Promise((resolve, reject) => {
        this.$refs.refScheduleObserver.validate().then((success) => {
          if (success) {
            resolve(true);
          } else {
            reject();
          }
        });
      });
    },
    calcLoanCloseDate(item) {
      if (
        item.loan_date &&
        item.period &&
        (item.is_monthly || item.is_monthly == 0)
      ) {
        const data = {
          loan_date: item.loan_date,
          period: item.period,
          is_monthly: item.is_monthly,
          first_pay_date: item.first_pay_date
        };

        if(data.loan_date == this.tem_data.loan_date && data.period == this.tem_data.period
         && data.is_monthly == this.tem_data.is_monthly
          && data.first_pay_date == this.tem_data.first_pay_date
        ){
          return;
        }
        this.tem_data = {...data};
        console.log({data, tem_data:this.tem_data})
        store
          .dispatch("app-loan/getContractEndDate", data)
          .then((response) => {
            console.log({data})
            
            item.first_pay_date = response.data.data.paid_date[0];
            item.contract_end_date = response.data.data.contract_end_date;
            console.log("pay_date");
            if (item.is_monthly == 1) {
              if (response.data.data.paid_date.length > 0) {
                //get the last two digit of the date
                item.pay_date =
                  "每月" + response.data.data.paid_date[0].slice(-2) + "號";
              }
            } else {
              if (response.data.data.paid_date.length > 1) {
                //get the last two digit of the date

                let date1 = parseInt(response.data.data.paid_date[0].slice(-2));
                let date2 = parseInt(response.data.data.paid_date[1].slice(-2));
                // small number first

                if (date1 > date2) {
                  item.pay_date = "每月" + date2 + "號及" + date1 + "號";
                } else {
                  item.pay_date = "每月" + date1 + "號及" + date2 + "號";
                }
              }
            }
          })
          .catch((error) => {
            this.$swal({
              text: JSON.stringify(error.response.data.message),
              icon: "error",
              cancelButtonText: "取消",
              customClass: {
                confirmButton: "btn btn-primary",
              },
              showClass: {
                popup: "animate__animated animate__bounceIn",
              },
              buttonsStyling: false,
            });
          });
      }
    },
    getArrayString($arr) {
      if ($arr.length == 0) return "";
      // my array if a object with the field of name, i would like to join all the name field with a '/'
      return $arr
        .map(function (item) {
          return item.name;
        })
        .join("/");
    },
    hide() {
      this.$router.replace("/contracts");
    },
    formSubmitted() {

      if(this.newContractData.hasOwnProperty('supplier'))
        delete this.newContractData.supplier
      if(this.newContractData.hasOwnProperty('payment_schedules'))
        delete this.newContractData.payment_schedules
      
      this.$swal({
        title: "你確定嗎?",
        showCancelButton: true,
        confirmButtonText: "提交",
        cancelButtonText: "取消",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.loading = true;
          store
            .dispatch("app-contract/addContract", this.newContractData)
            .then((response) => {
              this.loading = false;
              this.$emit("update:allow-to-leave", true);
              this.$swal({
                text: response.data.message,
                icon: "success",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });

              if (this.newContractData.id == 0 || true) {
                this.hide();
              }
            })
            .catch((error) => {
              this.loading = false;
              this.$swal({
                text: JSON.stringify(error.response.data.message),
                icon: "error",
                confirmButtonText: "確定",
                customClass: {
                  confirmButton: "btn btn-primary",
                },
                showClass: {
                  popup: "animate__animated animate__bounceIn",
                },
                buttonsStyling: false,
              });
            });
        }
      });
    },
    makeToast(variant = null, title = null, content = null) {
      this.$bvToast.toast(content, {
        title: title,
        variant,
        solid: true,
      });
    },
    IsHKID(str) {
      var strValidChars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

      // basic check length
      if (str.length < 8) {
        this.idError = "身份證號碼" + str + "不正確";
        return false;
      }

      // handling bracket
      if (
        str.charAt(str.length - 3) == "(" &&
        str.charAt(str.length - 1) == ")"
      )
        str = str.substring(0, str.length - 3) + str.charAt(str.length - 2);

      // convert to upper case
      str = str.toUpperCase();

      // regular expression to check pattern and split
      var hkidPat = /^([A-Z]{1,2})([0-9]{6})([A0-9])$/;

      var matchArray = str.match(hkidPat);

      // not match, return false
      if (matchArray == null) {
        this.idError = "身份證號碼" + str + "不正確";
        return;
      }

      // the character part, numeric part and check digit part
      var charPart = matchArray[1];
      var numPart = matchArray[2];
      var checkDigit = matchArray[3];

      // calculate the checksum for character part
      var checkSum = 0;
      if (charPart.length == 2) {
        checkSum += 9 * (10 + strValidChars.indexOf(charPart.charAt(0)));
        checkSum += 8 * (10 + strValidChars.indexOf(charPart.charAt(1)));
      } else {
        checkSum += 9 * 36;
        checkSum += 8 * (10 + strValidChars.indexOf(charPart));
      }

      // calculate the checksum for numeric part
      for (var i = 0, j = 7; i < numPart.length; i++, j--)
        checkSum += j * numPart.charAt(i);

      // verify the check digit
      var remaining = checkSum % 11;
      var verify = remaining == 0 ? 0 : 11 - remaining;

      if (verify == checkDigit || (verify == 10 && checkDigit == "A")) {
        this.idError = "";
      } else {
        this.idError = "身份證號碼" + str + "不正確";
      }
    },
  },

  props: {
    allowToLeave: {
      type: Boolean,
      required: true,
    },
    contractData: {
      type: Object,
      required: true,
    },
    supplierOptions: {
      type: Array,
      required: true,
    },
    loanTypeOptions: {
      type: Array,
      required: true,
    },
    paymentMethodOptions: {
      type: Array,
      required: true,
    },
    interestTypeOptions: {
      type: Array,
      required: true,
    },
    genderOptions: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    const newContractData = ref({
      contracts: [JSON.parse(JSON.stringify({ id: 0 }))],
    });
    const resetContractData = () => {
      props.contractData = {
        contracts: [JSON.parse(JSON.stringify({ id: 0 }))],
      };
    };
    watch(
      () => props.contractData,
      (newVal) => {
        newContractData.value = { ...newVal };
      }
    );

    watch(
      () => store.state.app.user_info,
      (newVal) => {
        if(newContractData.value.id == 0)
        {
          if(props.supplierOptions.length > 0)
          {
            newContractData.value.supplier_create_id = parseInt(store.state.app.user_info.userData.parent_id || store.state.app.user_info.userData.id);
            let supplier = props.supplierOptions.find((x) => x.id == newContractData.value.supplier_create_id);
            newContractData.value.supplier_name_en = supplier.company_name_en;
            newContractData.value.supplier_name_cn = supplier.company_name_cn;
            newContractData.value.moneylender_number =
              supplier.moneylender_number;
            newContractData.value.supplier_address = supplier.address;
          }
        }
      }
    );

    watch(
        () => props.supplierOptions,
        (newVal) => {
          newContractData.value.supplier_create_id = parseInt(store.state.app.user_info.userData.parent_id || store.state.app.user_info.userData.id);
          if(newContractData.value.id == 0 && newContractData.value.supplier_create_id)
          {
            let supplier = newVal.find((x) => x.id == newContractData.value.supplier_create_id);
            newContractData.value.supplier_name_en = supplier.company_name_en;
            newContractData.value.supplier_name_cn = supplier.company_name_cn;
            newContractData.value.moneylender_number =
              supplier.moneylender_number;
            newContractData.value.supplier_address = supplier.address;
          }
        }
      );


    const loading = ref(false);

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetContractData);

    return {
      refFormObserver,
      getValidationState,
      resetForm,
      loading,
      newContractData,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/libs/vue-select.scss";
</style>

<style lang="scss" scoped>
</style>
